// General
import { useTranslation } from 'gatsby-plugin-react-i18next';
// Hooks
import { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

// UI Kit
import LoadingMessage from 'ui-kit/loading-message/loading-message';
import ToastBox from 'ui-kit/toast-box/toast-box';

import MyCaregiversCardList from 'display-components/family-management/my-caregivers-card-list';

// Components
import { BirdiModalHeaderDanger, BirdiModalHeaderWithBackgroundColor } from 'components/birdi-modal/birdi-modal-header';
import { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import {
    familyProfileGetCaregiversRoutine,
    familyProfilePostChangeCaregiversAccessRoutine
} from 'state/family-profile/family-profile.routines';
// State
import {
    familyIsCaregiverLoadingSelector,
    familyProfileCaregiversSelector
} from 'state/family-profile/family-profile.selectors';

// Interface and types
import { Caregiver } from 'types/family-management';

import { getPhoneNumber } from 'util/globalVariables';
// Util
import { convertToTitleCase } from 'util/string';

// Main component
const MyCaregiversList = () => {
    // General
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // States
    const [isBusy, setIsBusy] = useState<boolean>(false);

    // Selectors
    const caregivers = useSelector(familyProfileCaregiversSelector);
    const isCaregiversLoading = useSelector(familyIsCaregiverLoadingSelector);

    // Handle Accept Caregiver invitation.
    const handleAccept = useCallback(
        (caregiver: Caregiver) => {
            setIsBusy(true);
            dispatch(
                familyProfilePostChangeCaregiversAccessRoutine.trigger({
                    data: {
                        Action: 0,
                        EPostPatientNumber: caregiver.ePostPatientNumber
                    },
                    onSuccess: () => {
                        dispatch(familyProfileGetCaregiversRoutine.trigger());
                        setIsBusy(false);
                        openModal({
                            showClose: false,
                            type: 'primary',
                            size: 'lg',
                            headerContent: (
                                <BirdiModalHeaderWithBackgroundColor
                                    headerText={t('modals.familyAccount.caregiverAccept.title')}
                                />
                            ),
                            bodyContent: (
                                <BirdiModalContentAlt
                                    subTitle={t('modals.familyAccount.caregiverAccept.title', {
                                        caregiverName: convertToTitleCase(caregiver.caregiverFirstName)
                                    })}
                                />
                            ),
                            ctas: [
                                {
                                    label: t('modals.familyAccount.caregiverAccept.button'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    }
                                }
                            ]
                        });
                    },
                    onFailure: (response: string) => {
                        setIsBusy(false);
                        dispatch(
                            openModal({
                                showClose: true,
                                type: 'danger',
                                size: 'lg',
                                headerContent: (
                                    <BirdiModalHeaderDanger
                                        icon="alert"
                                        headerText={t('modals.familyAccount.caregiverGenericError')}
                                    />
                                ),
                                bodyContent: (
                                    <BirdiModalContentAlt
                                        subTitle={
                                            response.indexOf('is already being managed')
                                                ? t('modals.familyAccount.caregiverChangeAccessAlreadyMemberError')
                                                : t('modals.familyAccount.caregiverChangeAccessError')
                                        }
                                        note={t('modals.familyAccount.furtherAssistance', {
                                            phoneNumber: getPhoneNumber({ isEnd: true })
                                        })}
                                    />
                                ),
                                ctas: [
                                    {
                                        label: t('modals.default.submit'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        }
                                    }
                                ]
                            })
                        );
                    }
                })
            );
        },
        [dispatch, t]
    );

    // Handle decline Caregiver invitation.
    const handleDecline = useCallback(
        (caregiver: Caregiver) => {
            dispatch(
                openModal({
                    showClose: false,
                    size: 'lg',
                    type: 'danger',
                    headerContent: (
                        <BirdiModalHeaderDanger
                            headerText={t('modals.familyAccount.caregiverDecline.title')}
                            icon="alert"
                        />
                    ),
                    bodyContent: (
                        <BirdiModalContentAlt
                            subTitle={t('modals.familyAccount.caregiverDecline.body', {
                                caregiverName: convertToTitleCase(caregiver.caregiverFirstName)
                            })}
                        />
                    ),
                    ctas: [
                        {
                            label: t('modals.familyAccount.caregiverDecline.cancelButton'),
                            variant: 'link',
                            onClick: () => {
                                dispatch(closeModal({}));
                            },
                            dataGALocation: 'FamilyAccountProfile'
                        },
                        {
                            label: t('modals.familyAccount.caregiverDecline.decline'),
                            variant: 'primary',
                            onClick: () => {
                                setIsBusy(true);
                                dispatch(closeModal({}));
                                dispatch(
                                    familyProfilePostChangeCaregiversAccessRoutine.trigger({
                                        data: {
                                            Action: 1,
                                            EPostPatientNumber: caregiver.ePostPatientNumber
                                        },
                                        onSuccess: () => {
                                            setIsBusy(false);
                                            dispatch(familyProfileGetCaregiversRoutine.trigger());
                                        },
                                        onFailure: () => {
                                            setIsBusy(false);
                                            dispatch(
                                                openModal({
                                                    showClose: true,
                                                    type: 'danger',
                                                    size: 'lg',
                                                    headerContent: (
                                                        <BirdiModalHeaderDanger
                                                            icon="alert"
                                                            headerText={t('modals.familyAccount.caregiverGenericError')}
                                                        />
                                                    ),
                                                    bodyContent: (
                                                        <BirdiModalContentAlt
                                                            subTitle={t(
                                                                'modals.familyAccount.caregiverChangeAccessError'
                                                            )}
                                                        />
                                                    ),
                                                    ctas: [
                                                        {
                                                            label: t('modals.default.submit'),
                                                            variant: 'primary',
                                                            onClick: () => {
                                                                dispatch(closeModal({}));
                                                            }
                                                        }
                                                    ]
                                                })
                                            );
                                        }
                                    })
                                );
                            },
                            dataGALocation: 'FamilyAccountProfile'
                        }
                    ]
                })
            );
        },
        [dispatch, t]
    );

    // Handle revoke Caregiver access.
    const handleRevokeAccess = useCallback(
        (caregiver: Caregiver) => {
            dispatch(
                openModal({
                    showClose: false,
                    size: 'lg',
                    type: 'danger',
                    headerContent: (
                        <BirdiModalHeaderDanger
                            headerText={t('modals.familyAccount.caregiverRevokeAccess.title')}
                            icon="alert"
                        />
                    ),
                    bodyContent: (
                        <BirdiModalContentAlt
                            subTitle={t('modals.familyAccount.caregiverRevokeAccess.body', {
                                caregiverName: convertToTitleCase(caregiver.caregiverFirstName)
                            })}
                        />
                    ),
                    ctas: [
                        {
                            label: t('modals.familyAccount.caregiverRevokeAccess.cancelButton'),
                            variant: 'link',
                            onClick: () => {
                                dispatch(closeModal({}));
                            },
                            dataGALocation: 'FamilyAccountProfile'
                        },
                        {
                            label: t('modals.familyAccount.caregiverRevokeAccess.revokeButton'),
                            variant: 'primary',
                            onClick: () => {
                                setIsBusy(true);
                                dispatch(closeModal({}));
                                dispatch(
                                    familyProfilePostChangeCaregiversAccessRoutine.trigger({
                                        data: {
                                            Action: 2,
                                            EPostPatientNumber: caregiver.ePostPatientNumber
                                        },
                                        onSuccess: () => {
                                            setIsBusy(false);
                                            dispatch(familyProfileGetCaregiversRoutine.trigger());
                                        },
                                        onFailure: () => {
                                            setIsBusy(false);
                                            dispatch(
                                                openModal({
                                                    showClose: true,
                                                    type: 'danger',
                                                    size: 'lg',
                                                    headerContent: (
                                                        <BirdiModalHeaderDanger
                                                            icon="alert"
                                                            headerText={t('modals.familyAccount.caregiverGenericError')}
                                                        />
                                                    ),
                                                    bodyContent: (
                                                        <BirdiModalContentAlt
                                                            subTitle={t(
                                                                'modals.familyAccount.caregiverChangeAccessError'
                                                            )}
                                                        />
                                                    ),
                                                    ctas: [
                                                        {
                                                            label: t('modals.default.submit'),
                                                            variant: 'primary',
                                                            onClick: () => {
                                                                dispatch(closeModal({}));
                                                            }
                                                        }
                                                    ]
                                                })
                                            );
                                        }
                                    })
                                );
                            },
                            dataGALocation: 'FamilyAccountProfile'
                        }
                    ]
                })
            );
        },
        [dispatch, t]
    );

    // Preparing caregiver payload to send to My Caregiver Card component.
    const caregiversPayload = caregivers.map((caregiver) => {
        const showRevokeButton =
            caregiver.accountStatus === 'fullAccess' || caregiver.accountStatus === 'partialAccess';
        const showCtaButtons = caregiver.accountStatus === 'responsePending';

        return {
            ...caregiver,
            isBusy,
            onAccept: showCtaButtons ? () => handleAccept(caregiver) : undefined,
            onDecline: showCtaButtons ? () => handleDecline(caregiver) : undefined,
            onRevokeAccess: showRevokeButton ? () => handleRevokeAccess(caregiver) : undefined
        };
    });

    const hasResponsePending = caregivers.some((caregiver) => caregiver.accountStatus === 'responsePending');

    // Get My Caregivers list.
    useEffect(() => {
        dispatch(familyProfileGetCaregiversRoutine.trigger());
    }, [dispatch]);

    return (
        <Container fluid>
            <Row>
                <Col>
                    {isCaregiversLoading ? (
                        <LoadingMessage
                            text={t(`pages.profile.familyAccount.familySection.loadingMessage`)}
                            isVisible={isCaregiversLoading}
                        />
                    ) : caregivers.length > 0 ? (
                        <>
                            <MyCaregiversCardList payload={caregiversPayload} />
                            {hasResponsePending && (
                                <div className="mt-2">
                                    <ToastBox variant="descriptive">
                                        {t('pages.profile.familyAccount.myCaregiversSection.note')}
                                    </ToastBox>
                                </div>
                            )}
                        </>
                    ) : (
                        <p className="profile-empty-message">
                            {t('pages.profile.familyAccount.myCaregiversSection.emptyMessage')}
                        </p>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default MyCaregiversList;
